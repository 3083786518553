.lottery-control-arrow {
  top: 0;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  transition: all .25s ease-in;
  position: absolute;
  z-index: 2;
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}
.lottery-control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.lottery-control-arrow.control-next {
  right: 0;
}