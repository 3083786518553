@import url(https://fonts.googleapis.com/css2?family=Jura&family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.card-filter.active {
  max-height: 200px;
}
.card-filter {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s;
}
.lottery-control-arrow {
  top: 0;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  transition: all .25s ease-in;
  position: absolute;
  z-index: 2;
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
}
.lottery-control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.lottery-control-arrow.control-next {
  right: 0;
}
